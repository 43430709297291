html {
  height: 100%;
}

#root, .App {

  height: 100%;
}

body {
  min-height: 100%;
}
